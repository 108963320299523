<template>
    <div class="row plain-element">
        <div v-if="!isLoggedIn()" class="dashboard-cards">
            <NoPermissionComponent/>
        </div>
        <div v-if="getUsername() && getSelectMenus()">
            <div class="modal fade" id="primarySelectionModal" tabindex="-1" aria-labelledby="primarySelectionModalLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 96%;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="row plain-element" style="width: 100%;">
                                <div class="col col-12 col-sm-2">
                                    <div class="row plain-element">
                                        <div class="input-field  col col-12 col-sm-12 col-md-2 col-lg-2">
                                            <label class="label-search opp-abb-lg">Area:</label>
                                        </div>
                                        <div class="input-field  col col-12 col-sm-12 col-md-8 col-lg-8">
                                            <select v-model="area" name="area" id="area" @change="clearSearch()" class="modal-select">
                                                <option value="" selected> All Areas</option>
                                                <option v-for="(element, index) in getSelectMenus().areas" :key="index">
                                                    {{ element }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col col-12 col-sm-2">
                                    <div class="row plain-element">
                                        <div class="input-field  col col-12 col-sm-12 col-md-2 col-lg-2">
                                            <label class="label-search opp-abb-lg">SEU:</label>
                                        </div>
                                        <div class="input-field  col col-12 col-sm-12 col-md-8 col-lg-8">
                                            <select v-model="seu" name="seu" id="seu" class="modal-select">
                                                <option value="" selected> All SEUs</option>
                                                <option v-for="(element, index) in getSelectMenus().seus" :key="index">
                                                    {{ element }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-sm-2">
                                    <div class="row plain-element">
                                        <div class="input-field  col col-12 col-sm-12 col-md-2 col-lg-2">
                                            <label class="label-search opp-abb-lg">Search:</label>
                                        </div>
                                        <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10">
                                            <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                                                   aria-label="e.g. Steam" v-model="search" style="height: 32px; width: 96%">
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-sm-2 text-end">
                                    <button type="button" class="btn btn-execute background-light-purple" @click="clearPrimarySelection()">Clear Selection</button>
                                </div>
                                <div class="col col-12 col-sm-2 text-end">

                                </div>
                                <div class="col col-12 col-sm-2 text-end">
                                    <button type="button" class="btn btn-execute" data-bs-dismiss="modal">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body" style="white-space: pre-wrap; text-align: left;">
                            <div v-if="getSelectMenus().enpi_meters.length > 0" class="row plain-element">
                                <div v-for="element, index in primaryKeywordFilteredList" class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element" :key="index">
                                    <div class="input-field col col-12 col-sm-12 col-form">
                                        <label>
                                            <input type="checkbox" :value="element.name" v-model="selectedMetersPrimary" style="position: relative; top: -1px;">
                                            <span style="font-size: 14px;">{{ element.name | truncatechars(45) }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row plain-element text-center">
                                <span style="font-size: 18px;">No {{ site }} EnPI Meters in database yet.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="secondarySelectionModal" tabindex="-1" aria-label="secondarySelectionModalLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 96%;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="row plain-element" style="width: 100%;">
                                <div class="col col-12 col-sm-2">
                                    <div class="row plain-element">
                                        <div class="input-field  col col-12 col-sm-12 col-md-2 col-lg-2">
                                            <label class="label-search opp-abb-lg">Area:</label>
                                        </div>
                                        <div class="input-field  col col-12 col-sm-12 col-md-8 col-lg-8">
                                            <select v-model="areaSecondary" name="areaSecondary" id="areaSecondary" @change="clearSearchSecondary()" class="modal-select">
                                                <option value="" selected> All Areas</option>
                                                <option v-for="(element, index) in getSelectMenus().areas" :key="index">
                                                    {{ index }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col col-12 col-sm-2">
                                    <div class="row plain-element">
                                        <div class="input-field  col col-12 col-sm-12 col-md-2 col-lg-2">
                                            <label class="label-search opp-abb-lg">SEU:</label>
                                        </div>
                                        <div class="input-field  col col-12 col-sm-12 col-md-8 col-lg-8">
                                            <select v-model="seuSecondary" name="seuSecondary" id="seuSecondary" class="modal-select">
                                                <option value="" selected> All SEUs</option>
                                                <option v-for="(element, index) in getSelectMenus().seus" :key="index">
                                                    {{ element }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-sm-3">
                                    <div class="row plain-element">
                                        <div class="input-field  col col-12 col-sm-12 col-md-2 col-lg-2">
                                            <label class="label-search opp-abb-lg">Search:</label>
                                        </div>
                                        <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10">
                                            <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                                                   aria-label="e.g. Steam" v-model="searchSecondary" style="height: 32px; width: 96%">
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-sm-5 text-end">
                                    <button type="button" class="btn btn-execute" data-bs-dismiss="modal">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body" style="white-space: pre-wrap; text-align: left;">
                            <div v-if="getSelectMenus().enpi_meters.length > 0" class="row plain-element">
                                <div v-for="element, index in secondaryKeywordFilteredList" class="col col-12 col-sm-12 col-md-3 col-lg-3 plain-element" :key="index">
                                    <div class="input-field col col-12 col-sm-12 col-form">
                                        <label>
                                            <input type="radio" style="position: relative; top: -1px;" :value="element.name" v-model="selectedSecondaryMeter">
                                            <span style="margin-left: 4px; font-size: 14px;">{{ element.name | truncatechars(45) }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row plain-element text-center">
                                <span style="font-size: 18px;">No {{ site }} EnPI Meters in database yet.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-cards">
                <div class="row text-start row-back">
                    <a href="/energyeye-select">
                        <i class="fas fa-chevron-left" aria-hidden="true"></i> Back To Selection
                    </a>
                </div>
            </div>
            <div class="row row-break"></div>
            <div class="plain-element text-center hidden">
                <span class="font-red"> We're working on an alternative to Energy Eye that doesn't rely on Power BI. It's still a work in progress, and we welcome any suggestions.</span>
                <div class="row row-break"></div>
            </div>
            <div v-if="getUsername()" class="dashboard" v-cloak>
                <div class="plain-element text-center">
                    <div class="row row-opportunities row-96">
                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-1 plain-element">
                            <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form">
                                <div class="row plain-element text-start">
                                    <label class="label-search">Start Date</label>
                                </div>
                                <datepicker
                                        v-model="start_date"
                                        :format="customFormatter"
                                        @input="handleDateChange"
                                        :use-utc="true"
                                >
                                </datepicker>
                            </div>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-1 plain-element">
                            <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form">
                                <div class="row plain-element text-start">
                                    <label class="label-search">End Date</label>
                                </div>
                                <datepicker
                                    v-model="end_date"
                                    :format="customFormatter"
                                    @input="handleDateChange2"
                                    :use-utc="true"
                                >
                                </datepicker>
                            </div>
                        </div>

                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-1 plain-element">
                            <div class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form">
                                <div class="row plain-element text-start">
                                    <label class="label-search">Interval</label>
                                </div>
                                <select v-model="interval" name="interval" id="interval">
                                    <option v-for="element, index in getSelectMenus().intervals" :value="element" :key="index"> {{element}}</option>
                                </select>
                            </div>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-3 plain-element">
                            <div class="input-field  col col-12 col-sm-12 col-md-10 col-form">
                                <div class="row plain-element text-start">
                                    <label class="label-search opp-abb-lg">Primary Selection</label>
                                    <label class="label-search opp-abb-md">Prim. Selection</label>
                                </div>
                                <div class="btn-modal" data-bs-toggle="modal" data-bs-target="#primarySelectionModal">
                                    <span v-if="selectedMetersPrimary.length > 0">{{ arrayToSting(selectedMetersPrimary) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="secondarySelection" class=" col col-12 col-sm-12 col-md-2 col-lg-3 plain-element">
                            <div class="input-field  col col-12 col-sm-12 col-md-10 col-form">
                                <div class="row plain-element text-start">
                                    <label class="label-search opp-abb-lg">Secondary Selection</label>
                                    <label class="label-search opp-abb-md">Sec. Selection</label>
                                </div>
                                <div class="btn-modal" data-bs-toggle="modal" data-bs-target="#secondarySelectionModal">
                                    <span v-if="selectedSecondaryMeter.length > 0">{{ selectedSecondaryMeter }}</span>
                                </div>
                            </div>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-2 col-lg-3 plain-element text-start">
                            <button v-if="!secondarySelection" class="btn btn-powerbi" @click="toggleSecondarySelection()">
                                Add Secondary Selection
                            </button>
                            <button v-else class="btn btn-powerbi background-light-purple" @click="toggleSecondarySelection()">
                                Remove Secondary Selection
                            </button>
                        </div>
                    </div>
                    <div class="row row-break"></div>
                    <div v-if="!getLoader() && selectedMetersPrimary.length > 0" class="row row-opportunities row-96">
                        <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-start">
                            <button class="btn btn-execute" @click="executeQuery()">
                                Load Data
                            </button>
                        </div>
                    </div>
                    <div v-if="!getLoader() && selectedMetersPrimary.length == 0" class="row row-opportunities row-96">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                            <p>
                                Select Meter
                            </p>
                        </div>
                    </div>
                    <div v-if="getLoader()" class="loader" id="loader"></div>
                    <div class="row row-break"></div>
                    <p class="error" v-if="getFormError()">
                        <span id="formError">{{ getFormError() }}</span>
                    </p>
                    <div v-if="queryExecuted && !getLoader() && !getFormError()" class="row row-opportunities row-96">
                        <div class="col col-4 col-sm-4 col-md-10 col-lg-10 plain-element text-start">
                            <div class="row plain-element">
                                <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
                                    <div class="btn-plans-tab-secondary" @click="activateChartTab('area')">
                                        <div v-if="chart_tab === 'area'" class="tab-plans-active"> Area Chart</div>
                                        <div v-else> Area Chart</div>
                                    </div>
                                </div>
                                <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
                                    <div class="btn-plans-tab-secondary" @click="activateChartTab('bar')">
                                        <div v-if="chart_tab === 'bar'" class="tab-plans-active"> Bar Chart</div>
                                        <div v-else> Bar Chart</div>
                                    </div>
                                </div>
                                <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
                                    <div class="btn-plans-tab-secondary" @click="activateChartTab('distribution')">
                                        <div v-if="chart_tab === 'distribution'" class="tab-plans-active"> Distribution</div>
                                        <div v-else> Distribution</div>
                                    </div>
                                </div>
                                <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
                                    <div class="btn-plans-tab-secondary" @click="activateChartTab('stats')">
                                        <div v-if="chart_tab === 'stats'" class="tab-plans-active"> Statistics</div>
                                        <div v-else> Statistics</div>
                                    </div>
                                </div>
                                <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
                                    <div class="btn-plans-tab-secondary" @click="activateChartTab('download')">
                                        <div v-if="chart_tab === 'download'" class="tab-plans-active"> Download Data</div>
                                        <div v-else> Download Data</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-break"></div>
                    <div v-if="getAreaGraphData() && !getLoader() && (chart_tab === 'area' || chart_tab === 'bar')" class="row row-opportunities row-96">
                        <div class="col col-sm-4 col-md-4 col-lg-2 plain-element">
                            <div class="row plain-element">
                                <div class="input-field col col-12 col-sm-12 col-md-12 col-lg-9 text-start" style="display: flex; align-items: center;">
                                    <label class="label-search" style="padding-right: 12px; white-space: nowrap;">Y-Min:</label>
                                    <input id="change" type="number" name="change" min="-1000000" max="1000000" step="1" v-model="ymin" class="validate" @change="updateCharts()">
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-4 col-md-4 col-lg-2 plain-element">
                            <div class="row plain-element">
                                <div class="input-field col col-12 col-sm-12 col-md-12 col-lg-9 text-start" style="display: flex; align-items: center;">
                                    <label class="label-search" style="padding-right: 12px; white-space: nowrap;">Y-Max:</label>
                                    <input id="change" type="number" name="change" min="-1000000" max="1000000" step="1" v-model="ymax" class="validate" @change="updateCharts()">
                                </div>
                            </div>
                        </div>

                        <div class="col col-sm-4 col-md-3 col-lg-2 plain-element">
                            <button class="btn btn-execute background-light-purple" @click="resetAxis()">Reset Axis</button>
                        </div>
                    </div>
                    <div v-if="getAreaGraphData()" class="row plain-element text-center">
                        <div v-if="getAreaGraphData().chartUnits" class="row plain-element text-center">
                            <div v-if="getAreaGraphData().chartUnits.includes(',')" class="row plain-element text-center" >
                                    <div style="padding: 24px 0px 0px 0px; color: #e7787a;">Multiple units selected on same axis</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="getAreaGraphData()" class="row row-opportunities row-96">
                        <div class="row row-break"></div>
                        <div v-if="chart_tab === 'area'" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                            <div v-if="getSecondaryAxis()" class="chart">
                                <energy-eye-area-chart-dual
                                :chart-data="getAreaGraphData()"
                                :styles="energyEyeChartStyles"
                                :chartInterval="getAreaGraphData().chartInterval"
                                :chartUnits="getAreaGraphData().chartUnits"
                                :secondaryChartUnits="getAreaGraphData().secondaryChartUnits"
                                :timeLabels="getAreaGraphData().time_labels"

                                style="min-height: 480px; margin-bottom: 60px;"></energy-eye-area-chart-dual>
                            </div>
                            <div v-else class="chart">
                                <energy-eye-area-chart
                                :chart-data="getAreaGraphData()"
                                :styles="energyEyeChartStyles"
                                :chartInterval="getAreaGraphData().chartInterval"
                                :chartUnits="getAreaGraphData().chartUnits"
                                :secondaryChartUnits="getAreaGraphData().secondaryChartUnits"
                                :timeLabels="getAreaGraphData().time_labels"

                                style="min-height: 480px; margin-bottom: 60px;"></energy-eye-area-chart>
                            </div>
                        </div>
                        <div v-if="chart_tab === 'bar'" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                            <div v-if="getSecondaryAxis()" class="chart">
                                <energy-eye-bar-chart-dual
                                :chart-data="getBarGraphData()"
                                :styles="energyEyeChartStyles"
                                :chartInterval="getAreaGraphData().chartInterval"
                                :chartUnits="getAreaGraphData().chartUnits"
                                :secondaryChartUnits="getAreaGraphData().secondaryChartUnits"
                                :timeLabels="getBarGraphData().time_labels"

                                style="min-height: 480px; margin-bottom: 60px;"></energy-eye-bar-chart-dual>
                            </div>
                            <div v-else class="chart">
                                <energy-eye-bar-chart
                                :chart-data="getBarGraphData()"
                                :styles="energyEyeChartStyles"
                                :chartInterval="getAreaGraphData().chartInterval"
                                :chartUnits="getAreaGraphData().chartUnits"
                                :secondaryChartUnits="getAreaGraphData().secondaryChartUnits"
                                :timeLabels="getBarGraphData().time_labels"

                                style="min-height: 480px; margin-bottom: 60px;"></energy-eye-bar-chart>
                            </div>
                        </div>
                        <div v-if="chart_tab === 'distribution'" class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                            <div class="chart">
                                <energy-eye-distribution-chart
                                :chart-data="getDistributionGraphData()"
                                :styles="energyEyeChartStyles"
                                :chartUnits="getAreaGraphData().chartUnits"
                                :secondaryChartUnits="getAreaGraphData().secondaryChartUnits"

                                style="min-height: 480px; margin-bottom: 60px;"></energy-eye-distribution-chart>
                            </div>
                        </div>
                        <div v-if="chart_tab === 'stats' && getStatisticalData()" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                            <div class="row  plain-element text-start">
                                <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                                    <div class="row  plain-element text-start">
                                        <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-start">
                                            <table class="table-metrics table-metadata">
                                                <tbody>
                                                <tr>
                                                    <th>Primary Selection</th>
                                                    <th class="text-end">Sum</th>
                                                    <th class="text-end">Mean</th>
                                                    <th class="text-end">Median</th>
                                                    <th class="text-end">St Dev</th>
                                                    <th class="text-end">Min</th>
                                                    <th class="text-end">Max</th>
                                                    <th class="text-end">%</th>
                                                </tr>
                                                <tr v-for="element, index in selectedMetersPrimary" :key="index">
                                                    <td style="font-size: 14px; padding: 4px; color: #0b5cfc;">{{ element }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[element].sum).toLocaleString()}}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[element].mean).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[element].median).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[element].std_dev).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[element].min).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[element].max).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px;">{{ getStatisticalData()[element].percentage }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">Total:</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ (getStatisticalData()["Total"].sum).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ (getStatisticalData()["Total"].mean).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ (getStatisticalData()["Total"].median).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ (getStatisticalData()["Total"].std_dev).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ (getStatisticalData()["Total"].min).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ (getStatisticalData()["Total"].max).toLocaleString() }}</td>
                                                    <td class="text-end" style="font-size: 14px; padding: 4px; font-weight: 500;">{{ getStatisticalData()["Total"].percentage }}</td>
                                                </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                                    <div class="row  plain-element text-start">
                                        <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-start">
                                            <div v-if="selectedSecondaryMeter.length > 0" class="row  plain-element text-start">
                                                <table class="table-metrics table-metadata">
                                                    <tbody>
                                                    <tr>
                                                        <th>Secondary Selection</th>
                                                        <th class="text-end">Sum</th>
                                                        <th class="text-end">Mean</th>
                                                        <th class="text-end">Median</th>
                                                        <th class="text-end">St Dev</th>
                                                        <th class="text-end">Min</th>
                                                        <th class="text-end">Max</th>
                                                        <th class="text-end">%</th>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 14px; padding: 4px; color: #5d4bc2;">{{ selectedSecondaryMeter }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[selectedSecondaryMeter].sum).toLocaleString() }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[selectedSecondaryMeter].mean).toLocaleString() }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[selectedSecondaryMeter].median).toLocaleString() }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[selectedSecondaryMeter].std_dev).toLocaleString() }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[selectedSecondaryMeter].min).toLocaleString() }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ (getStatisticalData()[selectedSecondaryMeter].max).toLocaleString() }}</td>
                                                        <td class="text-end" style="font-size: 14px; padding: 4px;">{{ getStatisticalData()[selectedSecondaryMeter].percentage }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="chart_tab === 'download' && !getLoader()" class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                            <div class="row  plain-element text-start">
                                <div class=" col col-12 col-sm-12 col-md-4 col-lg-4 plain-element text-start">
                                    <div class="card card-menu"  @click="downloadExcelQuery()">
                                        <div class="card-image">
                                          <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/excel.png" alt="Logo"/>
                                        </div>
                                        <div class="card-content">
                                          <span class="card-title">Download Excel</span>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col col-12 col-sm-12 col-md-4 col-lg-4 plain-element text-start">
                                    <div class="card card-menu"  @click="downloadCsvQuery()">
                                        <div class="card-image">
                                          <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/icons/csv.png" alt="Logo"/>
                                        </div>
                                        <div class="card-content">
                                          <span class="card-title">Download CSV</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { truncatechars } from "@/common/general.js";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"
import Datepicker from 'vuejs-datepicker';
import EnergyEyeAreaChart from "@/common/graphs/EnergyEyeAreaChart.js";
import EnergyEyeBarChart from "@/common/graphs/EnergyEyeBarChart.js";
import EnergyEyeAreaChartDual from "@/common/graphs/EnergyEyeAreaChartDual.js";
import EnergyEyeBarChartDual from "@/common/graphs/EnergyEyeBarChartDual.js";
import EnergyEyeDistributionChart from "@/common/graphs/EnergyEyeDistributionChart.js";

export default {
  name: "EnergyEyeView",
  components: {
      NoPermissionComponent,
      Datepicker,
      EnergyEyeAreaChart,
      EnergyEyeBarChart,
      EnergyEyeAreaChartDual,
      EnergyEyeBarChartDual,
      EnergyEyeDistributionChart
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    // Get the current date (end_date)
    const currentDate = new Date();

    // Calculate the date 60 days ago (start_date)
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 60);

    return {
        ymin: 0,
        area: "",
        seu: "",
        areaSecondary: "",
        seuSecondary: "",
        query: "",
        selectedMetersPrimary: [],
        selectedSecondaryMeter: "",
        queryExecuted: false,
        search: "",
        searchSecondary: "",
        year: pastDate.getFullYear(), // Automatically setting the year to pastDate's year
        month: pastDate.getMonth() + 1, // Automatically setting the month to pastDate's month
        interval: "Hourly",
        start_date: pastDate.toISOString().split('T')[0], // Formatting date as 'yyyy-MM-dd'
        end_date: currentDate.toISOString().split('T')[0], // Formatting date as 'yyyy-MM-dd'
        format: "yyyy-MM-dd",
        primary_selection: "test",
        secondarySelection: false,
        chart_tab: "area",
    }
  },
  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getSelectMenus", "getAreaGraphData", "getBarGraphData", "getYMax",
                    "getLoader", "getStatisticalData", "getDistributionGraphData", "getFormError", "getSecondaryAxis"]),
    ...mapActions([ "performSetFormError", "fetchSelectMenus", "performExecuteEnergyEyeQuery", "performDownloadEnergyEyeExcelQuery", "performDownloadEnergyEyeCsvQuery", "updateEnergyEyeCharts"] ),
    resetAxis() {
        this.ymax = null;
        this.updateEnergyEyeCharts({
            "area_dataset": this.getAreaGraphData(),
            "bar_dataset":  this.getBarGraphData(),
            "ymin": 0,
            "ymax": null,
        })
    },
    updateCharts() {
        this.updateEnergyEyeCharts({
            "area_dataset": this.getAreaGraphData(),
            "bar_dataset":  this.getBarGraphData(),
            "ymin": this.ymin,
            "ymax": this.ymax,
        })
    },
    executeQuery() {
        const totalMetersSelected = this.selectedMetersPrimary.length;
        // Check if the total number of selected meters exceeds 20
        if (totalMetersSelected > 20) {
            this.query = "Selection limit exceeded. Only 20 meters are allowed.";
        } else if (this.selectedMetersPrimary.length > 0) {
            this.query = "Retrieving range of " + this.interval + " data between " + this.start_date + " AND " + this.end_date + " for Primary meters: " +
            this.selectedMetersPrimary + " AND secondary meters: " + this.selectedSecondaryMeter;
            this.performExecuteEnergyEyeQuery({
                "site_code": this.site,
                "primary_meters": this.selectedMetersPrimary,
                "secondary_meter": this.selectedSecondaryMeter,
                "start_date": this.start_date,
                "end_date": this.end_date,
                "interval": this.interval
            });
            this.queryExecuted = true;
        } else {
            this.query = "Select Meters First";
        }

    },
    downloadExcelQuery() {
        const totalMetersSelected = this.selectedMetersPrimary.length;
        // Check if the total number of selected meters exceeds 20
        if (totalMetersSelected > 20) {
            this.query = "Selection limit exceeded. Only 20 meters are allowed.";
        } else if (this.selectedMetersPrimary.length > 0) {
            this.query = "Retrieving range of " + this.interval + " data between " + this.start_date + " AND " + this.end_date + " for Primary meters: " +
            this.selectedMetersPrimary + " AND secondary meters: " + this.selectedSecondaryMeter;
            this.performDownloadEnergyEyeExcelQuery({
                "site_code": this.site,
                "primary_meters": this.selectedMetersPrimary,
                "secondary_meter": this.selectedSecondaryMeter,
                "start_date": this.start_date,
                "end_date": this.end_date,
                "interval": this.interval
            })
            this.queryExecuted = true;
        } else {
            this.query = "Select Meters First";
        }
    },
    downloadCsvQuery() {
        const totalMetersSelected = this.selectedMetersPrimary.length;
        // Check if the total number of selected meters exceeds 20
        if (totalMetersSelected > 20) {
            this.query = "Selection limit exceeded. Only 20 meters are allowed.";
        } else if (this.selectedMetersPrimary.length > 0) {
            this.query = "Retrieving range of " + this.interval + " data between " + this.start_date + " AND " + this.end_date + " for Primary meters: " +
            this.selectedMetersPrimary + " AND secondary meters: " + this.selectedSecondaryMeter;
            this.performDownloadEnergyEyeCsvQuery({
                "site_code": this.site,
                "primary_meters": this.selectedMetersPrimary,
                "secondary_meter": this.selectedSecondaryMeter,
                "start_date": this.start_date,
                "end_date": this.end_date,
                "interval": this.interval
            })
            this.queryExecuted = true;
        } else {
            this.query = "Select Meters First";
        }
    },
    clearPrimarySelection() {
        this.selectedMetersPrimary = []
    },
    clearSearch() {
       this.search = "";
       this.seu = "";
    },
    clearSearchSecondary() {
       this.searchSecondary = "";
       this.seuSecondary = "";
    },
    customFormatter(date) {
      return date.toISOString().split("T")[0];
    },
    handleDateChange(date) {
      if (date) {
        // Ensure the date is stored without time zone
        this.start_date = date.toISOString().split("T")[0];
      } else {
        this.start_date = "";
      }
    },
    handleDateChange2(date) {
        if (date) {
            // Ensure the date is stored without time zone
          this.end_date = date.toISOString().split("T")[0];
        } else {
          this.end_date = "";
        }
    },
    activateChartTab(activatedChartTab) {
        this.ymax = null;
        this.chart_tab = activatedChartTab;
    },
    toggleSecondarySelection() {
      if (this.secondarySelection) {
        this.secondarySelection = false
        this.selectedSecondaryMeter = ""
      } else {
        this.secondarySelection = true
      }
    },
    arrayToSting(arr) {
        let str = arr.slice(0, 4).join(', ');

        // Check if the string length exceeds 40 characters
        if (str.length > 40) {
            // Cut the string and add "..."
            str = str.substring(0, 40) + "...";
        }

        return str;
    },
    goToCreateEnPI() {
        window.open(`/enpi-create`, '_blank');
    }
  },
  computed: {
      ymax: {
        get() {
          return this.$store.getters.getYMax;
        },
        set(value) {
          this.$store.commit('setYMax', value);
        }
      },
    areaFilteredList() {
        if (this.area !== "") {
            return this.getSelectMenus().enpi_meters.filter(meter => {
                return meter.area.includes(this.area)
            })
        }  else {
            return this.getSelectMenus().enpi_meters
        }
    },
    seuFilteredList() {
        if (this.seu !== "") {
            return this.areaFilteredList.filter(meter => {
                return meter.seu.includes(this.seu)
            })
        }  else {
            return this.areaFilteredList
        }

    },
    primaryKeywordFilteredList() {
      return this.seuFilteredList.filter(meter => {
        return meter.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    areaFilteredSecondaryList() {
        if (this.areaSecondary !== "") {
            return this.getSelectMenus().enpi_meters.filter(meter => {
                return meter.area.includes(this.areaSecondary)
            })
        }  else {
            return this.getSelectMenus().enpi_meters
        }
    },
    seuFilteredSecondaryList() {
        if (this.seuSecondary !== "") {
            return this.areaFilteredSecondaryList.filter(meter => {
                return meter.seu.includes(this.seuSecondary)
            })
        }  else {
            return this.areaFilteredSecondaryList
        }

    },
    secondaryKeywordFilteredList() {
      return this.seuFilteredSecondaryList.filter(meter => {
        return meter.name.toLowerCase().includes(this.searchSecondary.toLowerCase())
      })
    },
    energyEyeChartStyles() {
      return {
            position: "relative",
        }
    },
    date() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    parseStartDate() {
      var d = this.start_date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return year.toString() + "-" + month + "-" + day;
    },
    parseEndDate() {
      var d = this.end_date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return year.toString() + "-" + month + "-" + day;
    },

  },
  filters: {
    truncatechars: truncatechars
  },
  created() {
    document.title = "Energy Eye";
    this.performSetFormError(null);
    this.fetchSelectMenus(this.site);
    window.scrollTo(0,0);
  },
}
</script>
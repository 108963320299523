<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()"  class="dashboard-cards">
      <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()"  class="row-dashboards" v-cloak>
    <div class="row row-break-40"></div>
    <div class="row row-break-40"></div>
    <div v-if="getUsername() && getUserSiteServices()"  class="row row-cards">
      <div v-if="getUserSiteServices().includes('seu_dashboards')" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
        <a href="/powerbi-select/All">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/seu_dashboards.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">SEU Dashboards</span>
            </div>
          </div>
        </a>
      </div>
      <div v-if="getUserSiteServices().includes('energy_eye')" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
        <a href="/energyeye-select">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/energy_eye.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Energy Eye</span>
            </div>
          </div>
        </a>
      </div>
      <div v-if="getUserSiteServices().includes('plans')" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
        <a href="/site-plan-search">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/site_plans.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Site Plans</span>
            </div>
          </div>
        </a>
      </div>
      <div v-if="getUserSiteServices().includes('actions')" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
        <a href="/action-search">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/actions.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Actions</span>
            </div>
          </div>
        </a>
      </div>
      <div  v-if="getUserSiteServices().includes('opportunities')" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
        <a href="/opportunity-search">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/opportunities.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Opportunities</span>
            </div>
          </div>
        </a>
      </div>
      <div  v-if="getUserSiteServices().includes('seu_dashboards')" class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element">
        <a href="/metadata-select">
          <div class="card card-menu">
            <div class="card-image">
              <img class="img-menu" src="https://saperapublicstorage.blob.core.windows.net/static/img/sql_formulas.png" alt="Logo"/>
            </div>
            <div class="card-content">
              <span class="card-title">Metering Information</span>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>
  <div class="footer">
    <p>
      <a class="font-blue" target="_blank" href="https://em3-support.atlassian.net/servicedesk/customer/portal/3">Need Help?</a> • <a target="_blank" href="https://em3.ie/privacy-policy/">Privacy Policy</a> • © 2025 EM3 SAPERA - All Rights Reserved
    </p>
  </div>
</div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "Home",
  components: {
    NoPermissionComponent,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters([ "isLoggedIn", "getUsername", "getAdmin", "getCompanyName", "getCompanyString", "getStaff", "getUserSiteServices"]),
    ...mapActions(["getUserInfo", "getCompanyInfo", "fetchUserSiteServices"]),

  },
  computed: {

  },
  created() {
    window.scrollTo(0,0);
    this.getUserInfo();
    this.getCompanyInfo();
  },
  beforeRouteUpdate (to, from, next) {
    this.getUserInfo();
    next();
  }
}

</script>


<template>
  <div class="row plain-element">
    <div class="row plain-element">

      <p class="w-100"></p>
      <div v-if="databases_tab === 'full_meter_table'" class="row plain-element">
        <DatabasesMeterTableComponent
            :site_code = site_code
            :full_dataset_dict = sql_formulas.full_dataset_dict
          />
      </div>
      <div v-if="databases_tab === 'meter_list'" class="row plain-element">
        <DatabasesMeterListComponent
            :site_code = site_code
            :table_meters = table_meters
          />
      </div>
      <div v-if="databases_tab === 'sql_fixed_view'" class="row plain-element">
          <DatabasesSqlFixedViewComponent
              :site_code = site_code
              :table_meters = table_meters
              :fixed_formulas = sql_formulas.fixed_formulas
          />

      </div>
      <div v-if="databases_tab === 'sql_sapera_view'" class="row plain-element">
          <DatabasesSqlSaperaViewComponent
              :site_code = site_code
              :table_meters = table_meters
              :tvf_formulas = sql_formulas.tvf_formulas
          />

      </div>
      <div v-if="databases_tab === 'sql_diagram'" class="row plain-element">
          <DatabasesSqlDiagramComponent

          />

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { truncatechars } from "@/common/general.js";
import DatabasesMeterListComponent from "@/components/metadata/databases/DatabasesMeterListComponent.vue";
import DatabasesSqlDiagramComponent from "@/components/metadata/databases/DatabasesSqlDiagramComponent.vue";
import DatabasesSqlFixedViewComponent from "@/components/metadata/databases/DatabasesSqlFixedViewComponent.vue";
import DatabasesSqlSaperaViewComponent from "@/components/metadata/databases/DatabasesSqlSaperaViewComponent.vue";
import DatabasesMeterTableComponent from "@/components/metadata/databases/DatabasesMeterTableComponent.vue";

export default {
  name: "SiteMetadataDatabasesComponent",
  props: {
    site_code: {
      type: String,
      required: true,
    },
    table_meters: {
      type: Array,
      required: true,
    },
    sql_formulas: {
      type: Object,
      required: true,
    }
  },
  components: {
    DatabasesMeterListComponent,
    DatabasesSqlDiagramComponent,
    DatabasesSqlFixedViewComponent,
    DatabasesSqlSaperaViewComponent,
    DatabasesMeterTableComponent,
  },
  data() {
    return {
      status: "",
      search: "",
      databases_tab: "full_meter_table",

    }
  },
  methods: {
    ...mapGetters(["getUsername", "getLoader"]),
    activateDatabasesTab(activatedDatabasesTab) {
        this.databases_tab = activatedDatabasesTab
    },
  },

  computed: {

  },
  filters: {
    truncatechars: truncatechars
  },
  created() {

  },
  beforeRouteUpdate (to, from, next) {

    next();
  }

}
</script>

<template>
  <div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
      <NoPermissionComponent/>
    </div>
    <div v-if="getUsername()" class="dashboard" v-cloak>
      <div class="row row-cards">
        <div class="row text-start row-back">
          <a href="/">
            <i class="fas fa-chevron-left"></i> Back To Homepage
          </a>
        </div>
        <div class="card card-description">
            <div v-if="getUserSites()" class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
                  <div class="row plain-element">
                      <div class=" col col-12 col-sm-12 col-md-8 col-lg-11 plain-element">
                        <span class="section-header ">Select Site</span>
                      </div>
                  </div>
                </div>
                  <div class="row plain-element">
                    <div class=" col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                      <div v-for="value, key in getUserSites().slice(0, 12)" class="input-field  col col-12 col-sm-11 col-form" :key="key">
                        <a @click="gotToSiteMetadataView(value.site_code)">
                          <div class="card menu-card">
                            <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_name }} <span class="font-grey">[{{ value.site_code }}]</span></h5>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                      <div v-for="value, key in getUserSites().slice(12, 24)" class="input-field  col col-12 col-sm-11 col-form" :key="key">
                        <a @click="gotToSiteMetadataView(value.site_code)">
                          <div class="card menu-card">
                            <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_name }} <span class="font-grey">[{{ value.site_code }}]</span></h5>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                      <div v-for="value, key in getUserSites().slice(24, 36)" class="input-field  col col-12 col-sm-11 col-form" :key="key">
                        <a @click="gotToSiteMetadataView(value.site_code)">
                          <div class="card menu-card">
                            <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_name }} <span class="font-grey">[{{ value.site_code }}]</span></h5>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                      <div v-for="value, key in getUserSites().slice(36, 48)" class="input-field  col col-12 col-sm-11 col-form" :key="key">
                        <a @click="gotToSiteMetadataView(value.site_code)">
                          <div class="card menu-card">
                            <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_name }} <span class="font-grey">[{{ value.site_code }}]</span></h5>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                      <div v-for="value, key in getUserSites().slice(48, 60)" class="input-field  col col-12 col-sm-11 col-form" :key="key">
                        <a @click="gotToSiteMetadataView(value.site_code)">
                          <div class="card menu-card">
                            <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_name }} <span class="font-grey">[{{ value.site_code }}]</span></h5>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class=" col col-12 col-sm-12 col-md-8 col-lg-2 plain-element">
                      <div v-for="value, key in getUserSites().slice(60, )" class="input-field  col col-12 col-sm-11 col-form" :key="key">
                        <a @click="gotToSiteMetadataView(value.site_code)">
                          <div class="card menu-card">
                            <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_name }} <span class="font-grey">[{{ value.site_code }}]</span></h5>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
        </div>
      </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";

export default {
  name: "EnergyEyeSelectView",
  components: {
    NoPermissionComponent,

  },
  props: {

  },
  data() {
    return {};
  },
  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getServiceSitesAndSeu", "getLoader", "getFormError","getEnergyEyeDashboards", "getUserSites"]),
    ...mapActions(["performSetFormError", "fetchEnergyEyeDashboards"]),
    gotToSiteMetadataView(site_code) {
      if (event.ctrlKey === true) {
        window.open(`/site-metadata/${site_code}`, '_blank');
      } else {
        window.open(`/site-metadata/${site_code}`, '_blank');
      }
    },
  },
  created() {
    document.title = "Metadata";
    this.performSetFormError(null)
    window.scrollTo(0,0);
  }
}


</script>
<style scoped>
.expanded {
  cursor: pointer;
}
.expanded:hover {
  text-decoration: underline;
}
</style>
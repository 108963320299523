<template>
  <div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
      <NoPermissionComponent/>
    </div>
    <div v-if="getUsername()" class="dashboard" v-cloak>
      <div class="row row-cards">
        <div class="row text-start row-back">
          <a href="/metadata-select">
            <i class="fas fa-chevron-left"></i> Back To Metadata Search
          </a>
        </div>
        <div class="card card-details">
          <div class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
              <div class="row row-functions text-start">
                <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
                    <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                     class="img responsive img-icon">
                </div>
                <div v-if="getSiteNames()" class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element text-start">
                  <h3> {{ getSiteNames()[site_code] }}</h3>
                </div>

              </div>
            </div>
          </div>

          <p class="w-100"></p>
            <div v-if="getSqlFormulas()" class="row plain-element">
              <SiteMetadataDatabasesComponent
                :site_code = site_code
                :table_meters = getSqlFormulas().meters
                :sql_formulas = getSqlFormulas()
              />
            </div>
            <div v-else class="row">
                <div class="loader" id="loader"></div>
            </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";
import SiteMetadataDatabasesComponent from "@/components/metadata/SiteMetadataDatabasesComponent.vue";


export default {
  name: "SiteMetadataView",
  components: {
    NoPermissionComponent,
    SiteMetadataDatabasesComponent,
  },
  props: {
    site_code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      site: null,
      message: "",
    }
  },

  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getLoader", "getFormError", "getCompanyString", "getSiteNames", "getSiteEnpiTableMeters", "getSqlFormulas"]),
    ...mapActions(["performSetFormError", "fetchSiteEnpiTableMeters", "fetchSqlFormulas", "fetchSiteNames"]),
  },
  created() {
    document.title = "Site Metadata";
    this.performSetFormError(null);
    this.fetchSqlFormulas(this.site_code);
    this.fetchSiteNames();
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenSite = to.params.site_code;
    this.fetchSqlFormulas(this.chosenSite);
    this.fetchSiteNames();
    next();
  }
}
</script>
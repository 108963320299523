<template>
  <div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
      <NoPermissionComponent/>
    </div>
    <div v-if="getUsername()" class="dashboard" v-cloak>
      <div class="row row-cards">
        <div class="row text-start row-back">
          <a href="/">
            <i class="fas fa-chevron-left"></i> Back To Homepage
          </a>
        </div>
        <div class="card card-description">
            <div v-if="getEnergyEyeDashboards()" class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
                  <div class=" col col-12 col-sm-12 col-md-8 col-lg-11 plain-element">
                    <span class="section-header ">Select Site</span>
                  </div>
                </div>
            </div>
            <div v-if="getEnergyEyeDashboards()" class="row plain-element">
             <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
                <div  v-for="(value, key) in getEnergyEyeDashboards().slice(0,12)" class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form" :key="key" :value="value">
                    <a @click="goToEnergyEyeDashboard(value.site_code__site_code)">
                      <div class="card menu-card">
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-10 col-md-12 col-lg-12 plain-element">
                                <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_code__site_name }} <span class="font-grey">[{{ value.site_code__site_code }}]</span> </h5>
                            </div>
                        </div>
                      </div>
                    </a>
                </div>
             </div>
             <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
                <div  v-for="(value, key) in getEnergyEyeDashboards().slice(12,24)" class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10 col-form" :key="key" :value="value">
                    <a @click="goToEnergyEyeDashboard(value.site_code__site_code)">
                      <div class="card menu-card">
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-10 col-md-12 col-lg-12 plain-element">
                                <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_code__site_name }} <span class="font-grey">[{{ value.site_code__site_code }}]</span> </h5>
                            </div>
                        </div>
                      </div>
                    </a>
                </div>
             </div>
             <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
                <div  v-for="(value, key) in getEnergyEyeDashboards().slice(24,36)" class="input-field  col col-12 col-sm-12 col-md-10 col-lg-10  col-form" :key="key" :value="value">
                    <a @click="goToEnergyEyeDashboard(value.site_code__site_code)">
                      <div class="card menu-card">
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-10 col-md-12 col-lg-12 plain-element">
                                <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_code__site_name }} <span class="font-grey">[{{ value.site_code__site_code }}]</span> </h5>
                            </div>
                        </div>
                      </div>
                    </a>
                </div>
             </div>
             <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
                <div  v-for="(value, key) in getEnergyEyeDashboards().slice(36)" class="input-field  col col-12 col-sm-12 col-form" :key="key" :value="value">
                    <a @click="goToEnergyEyeDashboard(value.site_code__site_code)">
                      <div class="card menu-card">
                        <div class="row plain-element">
                            <div class=" col col-12 col-sm-10 col-md-12 col-lg-10 plain-element">
                                <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_code__site_name }} <span class="font-grey">[{{ value.site_code__site_code }}]</span> </h5>
                            </div>
                        </div>
                      </div>
                    </a>
                </div>
             </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";


export default {
  name: "EnergyEyeSelectView",
  components: {
    NoPermissionComponent,

  },
  props: {

  },
  data() {
    return {
      site: ""
    }
  },

  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getServiceSitesAndSeu", "getLoader", "getFormError","getEnergyEyeDashboards", "getSiteNames"]),
    ...mapActions(["performSetFormError", "fetchEnergyEyeDashboards"]),

    goToEnergyEyeDashboard(site_code) {
      window.open(`/energy-eye/${site_code}`, '_blank');
    },

  },
  created() {
    document.title = "Energy Eye";
    this.performSetFormError(null)
    this.fetchEnergyEyeDashboards();
    window.scrollTo(0,0);
  }
}


</script>
<style scoped>
.expanded {
  cursor: pointer;
}
.expanded:hover {
  text-decoration: underline;
}
</style>
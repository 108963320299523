<template>
  <div class="row plain-element">
    <div v-if="!isLoggedIn()" class="dashboard-cards">
      <NoPermissionComponent/>
    </div>
    <div v-if="getUsername()" class="dashboard" v-cloak>
      <div class="row row-cards">
        <div class="row text-start row-back">
          <a href="/">
            <i class="fas fa-chevron-left"></i> Back To Homepage
          </a>
        </div>

        <div class="card card-description">
        <div class="row-plans-tabs plain-element text-center">
          <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
            <div class="btn-plans-tab">
              <div class="tab-plans-active" @click="goToEnergyEyeStandardSearch">
                <span>Standard Search</span>
              </div>
            </div>
          </div>
          <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
            <div class="btn-plans-tab" @click="goToEnergyEyeSelectSearch">
              <div>Select Search</div>
            </div>
          </div>
        </div>
        <div v-if="getEnergyEyeDashboards()" class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-8 col-lg-3 plain-element">
              <div class=" col col-12 col-sm-12 col-md-8 col-lg-11 plain-element">
                <span class="section-header ">Select Site</span>
              </div>
              <div  v-for="(value, key) in getEnergyEyeDashboards()" class="input-field  col col-12 col-sm-7 col-form" :key="key" :value="value">
                <a @click="goToEnergyEyeDashboard(value.id)">
                  <div class="card menu-card">
                    <h5><i class="fas fa-chevron-right" aria-hidden="true"></i> {{ value.site_code__site_name }} </h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";


export default {
  name: "OldEnergyEyeSelectView",
  components: {
    NoPermissionComponent,

  },
  props: {

  },
  data() {
    return {
      site: ""
    }
  },

  methods: {
    ...mapGetters(["getUsername", "isLoggedIn", "getServiceSitesAndSeu", "getLoader", "getFormError","getEnergyEyeDashboards", "getSiteNames"]),
    ...mapActions(["performSetFormError", "fetchEnergyEyeDashboards"]),

    goToEnergyEyeDashboard(id) {
      window.open(`/power-bi/energyeye/${id}`, '_blank');
    },
    goToEnergyEyeStandardSearch() {
      location.href=(`/energyeye-select`);
    },
    goToEnergyEyeSelectSearch() {
      location.href=(`/power-bi/energyeye-dashboards`);
    },

  },
  created() {
    document.title = "Energy Eye";
    this.performSetFormError(null)
    this.fetchEnergyEyeDashboards();
    window.scrollTo(0,0);
  }
}


</script>
<style scoped>
.expanded {
  cursor: pointer;
}
.expanded:hover {
  text-decoration: underline;
}
</style>
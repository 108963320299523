<template>
    <div class="row plain-element">
        <div class="modal fade" id="enpiModal" tabindex="-1" aria-labelledby="enpiModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 50%;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="enpiModalLabel">{{ modalMeterName }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="white-space: pre-wrap; text-align: left;">
                        <span v-html="highlightOperators(modalMeterCalculation)"></span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fixed_formulas.length > 0" class="row plain-element">
            <div class=" col col-10 col-sm-4 col-md-4 col-lg-8 text-start">
                <span>Some meters from Meter List may need to undergo corrections before they are put in formulas. This is where you can find those changes.  </span>
            </div>
        </div>
        <p class="w-100"></p>
        <div v-if="fixed_formulas.length === 0" class="row plain-element text-start">
            <span>No Meter Tags table found for this site.</span>
        </div>
        <div v-else class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-4 col-lg-2 plain-element text-end">
                <div class="input-field  col col-12 col-sm-12 col-form">
                    <div class="row searchbox-wrapper">
                        <div class="row plain-element text-start">
                            <label class="label-search">Table Filter</label>
                        </div>
                        <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                               aria-label="e.g. Steam" v-model="search">
                    </div>
                </div>
            </div>
        </div>

        <div v-if="table_meters && getLoader() === false" class="row plain-element">

            <div v-if="table_meters.length > 0" class=" col col-12 col-sm-12 col-md-12 col-lg-9 text-start">

                <div id="parent">
                    <div id="child">
                        <table id="searchTable">
                            <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                            <tr>
                                <th @click="sortTableNew(0)" class="text-start">Tag Name
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>

                                </th>
                                <th @click="sortTableNew(1)" class="text-start">Description
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>
                                </th>
                                <th @click="sortTableNew(2)" class="text-start">Unit
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>
                                </th>

                                <th @click="sortTableNew(1)" class="text-start">SEU
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>
                                </th>
                                <th @click="sortTableNew(1)" class="text-start">Formula <span style="color: #0b5cfc">(CLICK ROW TO ENLARGE)</span>
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>
                                </th>
                            </tr>
                            </thead>
                            <thead v-else-if="keywordFilteredList.length > 10000">
                            <tr>
                                <th class="text-start">Tag Name
                                </th>
                                <th class="text-center">Formula(Click to Enlarge)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(meter, index) in keywordFilteredList" :key="index" @click="showModal(meter)" data-bs-toggle="modal" data-bs-target="#enpiModal">
                                <td class="text-start field-description">{{ meter.name }}</td>
                                <td class="text-start field-description">{{ meter.description }}</td>
                                <td class="text-start field-number">{{ meter.unit }}</td>
                                <td class="text-start field-number">{{ meter.seu }}</td>
                                <td class="text-start field-description">{{ meter.calculation | truncatechars(200) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">

                <div id="parent">
                    <div id="child">
                        <table id="searchTable">
                            <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                            <tr>
                                <th @click="sortTableNew(0)" class="text-start">Tag Name
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>

                                </th>
                                <th @click="sortTableNew(1)" class="text-start">Formula <span style="color: #0b5cfc">(CLICK ROW TO ENLARGE)</span>
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>
                                </th>

                            </tr>
                            </thead>
                            <thead v-else-if="keywordFilteredList.length > 10000">
                            <tr>
                                <th class="text-start">Tag Name
                                </th>
                                <th class="text-center">Formula(Click to Enlarge)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(meter, index) in keywordFilteredList" :key="index" @click="showModal(meter)" data-bs-toggle="modal" data-bs-target="#enpiModal">
                                <td class="text-start field-opportunity">{{ meter.name }}</td>
                                <td class="text-start field-description">{{ meter.calculation | truncatechars(200) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { sortTableNew, sortTableCurrencyInt } from "@/common/tables.js";
import { truncatechars } from "@/common/general.js";

export default {
  name: "DatabasesSqlFixedViewComponent",
  props: {
    site_code: {
      type: String,
      required: true,
    },
    table_meters: {
      type: Array,
      required: true,
    },
    fixed_formulas: {
      type: Array,
      required: true,
    },
  },
  components: {

  },
  data() {
    return {
      power_bi: "All",
      search: "",
      site: "",
      modalMeterName: "",
      modalMeterCalculation: ""
    }
  },
  methods: {
    ...mapGetters(["getLoader"]),
    sortTableNew: sortTableNew,
    sortTableCurrencyInt: sortTableCurrencyInt,
    clearSearch() {
       this.search = "";
    },
    replaceNewlines(text) {
      // Replace \r and \n with their escape sequences
      return text.replace('BETWEEN', '\n');
    },
    showModal(meter) {
      this.modalMeterName = meter.name;
      this.modalMeterCalculation = meter.calculation;
    },
    highlightOperators(text) {
    // Define regular expressions for SQL functions, strings, and comments
    const functionRegex = /\b(IIF|NULLIF|ISNULL|SUM|AVG|LAG|LEAD|COALESCE|CONCAT|FORMAT|COUNT|MAX|MIN|POWER|SQRT|DATEADD|DATEDIFF|DATEFROMPARTS|DAY|GETDATE|MONTH|YEAR)\b/gi;
    const stringRegex = /'[^']*'|"[^"]*"|\[[^\]]*\]/g;
    const commentRegex = /--.*$|\/\*[\s\S]*?\*\//gm;

    // Color code SQL functions
    text = text.replace(functionRegex, '<span class="sqlFunction">$&</span>');

    // Color code strings
    text = text.replace(stringRegex, (match) => {
        // If the match starts and ends with square brackets, color it light grey
        if (match.startsWith('[') && match.endsWith(']')) {
            return `<span class="lightGrey">${match}</span>`;
        } else {
            // Otherwise, leave the match unchanged
            return match;
        }
    });

    // Color code comments
    text = text.replace(commentRegex, (match) => {
        // Check if the comment contains any SQL function names
        if (functionRegex.test(match)) {
            // If so, color code both the comment and the SQL function names
            return match.replace(functionRegex, '<span class="comment">$&</span>');
        } else {
            // Otherwise, color code only the comment
            return `<span class="comment">${match}</span>`;
        }
    });

    return text;
}

  },
  computed: {
    filteredList() {
          return this.fixed_formulas
    },
    keywordFilteredList() {
      return this.filteredList
        .filter(meter =>
          meter.name.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.calculation.toLowerCase().includes(this.search.toLowerCase())
        );
    },

  },
  filters: {
    truncatechars: truncatechars
  },
  created() {


  },
}
</script>
<template>
       <div class="row plain-element">
          <div v-if="table_meters.length > 0" class="row plain-element">
            <div class=" col col-10 col-sm-4 col-md-4 col-lg-8 text-start">
              <span>This table lists all raw meters for <b>{{ site_code }}</b>  </span>
              <div class="info-container">
                  <p><b>Meter Name:</b> The name given to the meter by the site.</p>
                  <p><b>Meter Label:</b> The name given to the meter by an EM3 Engineer. This is the name seen in SEU dashboards. Energy Eye will always use the raw name</p>
                  <p><b>Unit:</b> The unit the meter is in.</p>
                  <p><b>In SAPERA View/ Energy Eye:</b> Whether the meter is in our SQL SAPERA View to be usable in Dashboards. For performance reasons we only pull the necessary meters into our dashboards.</p>
              </div>
            </div>
          </div>
          <p class="w-100"></p>
          <div v-if="table_meters.length === 0" class="row plain-element text-start">
                <span>No Meter Tags table found for this site.</span>
          </div>
          <div v-else class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
              <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
                <div class="row plain-element text-start">
                  <label class="label-search">In SAPERA View/ Energy Eye</label>
                </div>
                <select v-model="power_bi" name="power_bi" id="power_bi">
                  <option value="All" selected>All</option>
                </select>
              </div>
            </div>
            <div class=" col col-12 col-sm-12 col-md-4 col-lg-2 plain-element text-end">
              <div class="input-field  col col-12 col-sm-12 col-form">
                <div class="row searchbox-wrapper">
                  <div class="row plain-element text-start">
                    <label class="label-search">Table Filter</label>
                  </div>
                  <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                         aria-label="e.g. Steam" v-model="search">
                </div>
              </div>
            </div>
          </div>
          <div v-if="keywordFilteredList.length > 0 && Object.keys(keywordFilteredList[0]).length === 4" class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
            <div id="parent">
              <div id="child">
                  <table v-if="table_meters && getLoader() === false" id="searchTable">
                    <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                      <tr>
                        <th @click="sortTableNew(0)" class="text-start">Name
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>

                        </th>
                        <th @click="sortTableNew(1)" class="text-start">Description
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                        </th>
                        <th @click="sortTableNew(2)" class="text-start">SEU
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                        </th>
                        <th @click="sortTableNew(3)" class="text-center">Unit
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                        </th>
                      </tr>
                    </thead>
                    <thead v-else-if="keywordFilteredList.length > 10000">
                      <tr>
                        <th class="text-start">Meter Name
                        </th>
                        <th class="text-start">Description
                        </th>
                        <th class="text-center">SEU
                        </th>
                        <th class="text-center">Unit
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(meter, index) in keywordFilteredList" :key="index">
                        <td class="text-start field-opportunity">{{ meter.name }}</td>
                        <td class="text-start field-description">{{ meter.description}}</td>
                        <td class="text-start field-opportunity">{{meter.seu}}</td>
                        <td class="text-center field-number">{{meter.unit}}</td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 text-start">
            <div id="parent">
              <div id="child">
                  <table v-if="table_meters && getLoader() === false" id="searchTable">
                    <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                      <tr>
                        <th @click="sortTableNew(0)" class="text-start">Name
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>

                        </th>
                        <th @click="sortTableNew(1)" class="text-start">Description
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                        </th>
                        <th @click="sortTableNew(2)" class="text-center">Unit
                          <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                        </th>
                      </tr>
                    </thead>
                    <thead v-else-if="keywordFilteredList.length > 10000">
                      <tr>
                        <th class="text-start">Meter Name
                        </th>
                        <th class="text-start">Description
                        </th>
                        <th class="text-center">Unit
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(meter, index) in keywordFilteredList" :key="index">
                        <td class="text-start field-opportunity">{{ meter.name }}</td>
                        <td class="text-start field-description">{{ meter.description}}</td>
                        <td class="text-center field-number">{{meter.unit}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

      </div>
</template>

<script>

import { mapGetters } from "vuex";
import { sortTableNew, sortTableCurrencyInt } from "@/common/tables.js";

export default {
  name: "DatabasesMeterListComponent",
  props: {
    site_code: {
      type: String,
      required: true,
    },
    table_meters: {
      type: Array,
      required: true,
    }
  },
  components: {

  },
  data() {
    return {
      power_bi: "All",
      search: "",
      site: "",
    }
  },
  methods: {
    ...mapGetters(["getLoader"]),
    sortTableNew: sortTableNew,
    sortTableCurrencyInt: sortTableCurrencyInt,
    clearSearch() {
       this.search = "";
    },
  },
  computed: {
    filteredList() {
        if (this.power_bi !== "All") {
            return this.table_meters.filter(meter => {
              return meter.in_dashboard == this.power_bi
            })
        } else {
          return this.table_meters
        }
    },
    keywordFilteredList() {
      return this.filteredList
        .filter(meter =>
          meter.name.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.description.toLowerCase().includes(this.search.toLowerCase())
        );
    },

  },
  filters: {

  },
  created() {


  },
}
</script>

import api from "../../api/api.js"

const state = {
  siteMetadata: null,
  sqlFormulas: null,
};


const getters = {
  getSiteMetadata: state => state.siteMetadata,
  getSqlFormulas: state => state.sqlFormulas,
};


const actions = {
  async fetchSqlFormulas({commit}, payload) {
    commit("setLoader", true);
    const response = await api.sqlFormulas(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setSqlFormulas", response);
      commit("setLoader", false);
    }
  },
  async fetchSiteMetadata({commit}, payload) {
    commit("setLoader", true);
    const response = await api.siteMetadata(payload);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setSiteMetadata", response);
      commit("setLoader", false);

    }
  },
};

const mutations = {
  setSiteMetadata: (state, siteMetadata) => {
    state.siteMetadata = siteMetadata
  },
  setSqlFormulas: (state, sqlFormulas) => {
     state.sqlFormulas = sqlFormulas
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}
<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
    <div v-if="getUsername() && getServiceSitesAndSeu()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'action-search'}" class="horizontal-link underline-green">Action Search
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="row plain-element">
    <div class="row row-opportunities">
       <div class="row plain-element">
          <div v-if="getServiceSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site </label>
              </div>
              <select v-model="site" name="site" id="site" @change="clearSeu()">
                <option value="" selected> All Sites</option>
                <option v-for="(key, value, index) in getServiceSitesAndSeu()" :key="index" :value="value">
                  {{ getSiteNames()[value] }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getServiceSitesAndSeu()" class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">SEU</label>
              </div>
              <select v-model="seu" name="seu" id="seu" @change="clearSearch()">
                <option value="" selected> All SEU</option>
                <option v-for="(element, index) in getServiceSitesAndSeu()[site]" :key="index">
                  {{ element }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="getServiceSitesAndSeu()" class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Status</label>
              </div>
              <select v-model="status" name="status" id="status" @change="clearSearch()">
                <option value="open" selected>open</option>
                <option value="closed - resolved" selected> closed - resolved</option>
                <option value="closed - unresolved" selected> closed - unresolved</option>
                <option value="" selected> All</option>
              </select>
            </div>
          </div>
          <div v-if="getServiceSitesAndSeu()" class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Switch Currency</label>
              </div>
              <select v-model="dashboardCurrency" name="dashboardCurrency" id="dashboardCurrency" class="currency-select">
                    <option value="EUR" selected> EUR</option>
                    <option value="USD"> USD</option>
                    <option value="GBP"> GBP</option>
                    <option value="CAD"> CAD</option>
                    <option value="CNY"> CNY</option>
                    <option value="KRW"> KRW</option>
                    <option value="CHF"> CHF</option>
                    <option value="JPY"> JPY</option>
              </select>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-4 col-lg-2 plain-element text-end">
            <div v-if="getServiceSitesAndSeu()" class="input-field  col col-12 col-sm-12 col-form">
              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
       </div>
      <p class="w-100"></p>
      <div v-if="getUsername() && getLoader() === true" class="row">
        <div class="loader" id="loader"></div>
      </div>
      <div v-if="getActions()  && getLoader() === false" class="row plain-element">
        <div v-if="getResultCount() > 0" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
          <h6> {{ keywordFilteredList.length }} Results</h6>
        </div>
        <div class="col col-sm-6 col-md-6 col-lg-6 plain-element">
        </div>
        <div v-if="getResultCount() && keywordFilteredList.length > 0" class="col col-sm-3 col-md-3 col-lg-3 col-results plain-element text-end">
          <a target="_blank" v-if="site!=='' && seu===''" :href="'/api/site-search-actions-download/' + site + '/'"
             class="btn btn-report">
            <i class="fas fa-file-download"></i>&nbsp; Download
          </a>
          <a target="_blank" v-else-if="site!=='' && seu!==''"
             :href="'/api/seu-download/' + site + '/' + seu + '/'"
             class="btn btn-report">
            <i class="fas fa-file-download"></i>&nbsp; Download
          </a>
        </div>
      </div>
      <p class="w-100"></p>
      <div id="parent">
        <div id="child">
          <table v-if="getActions() && getLoader() === false"  id="searchTable">
            <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
              <tr>
                <th @click="sortTableNew(0)" class="text-center">Created
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th class="text-center th-description">Comment</th>
                <th @click="sortTableNew(2)" class="text-center">Status
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>

                <th @click="sortTableNew(3)" class="text-center">Site
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(4)" class="text-center">SEU
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
                <th @click="sortTableNew(5)" class="text-center">Event Type
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>

                <th @click="sortOppTableCurrencyInt(7)" class="text-end">Cost({{getDashboardCurrencies()[dashboardCurrency].symbol}})
                  <span class="fa fa-stack">
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                      </span>
                </th>
              </tr>
            </thead>
            <thead v-else-if="keywordFilteredList.length > 10000">
              <tr>
                <th class="text-center">Created</th>
                <th class="text-center th-description">Comment</th>
                <th class="text-center">Status</th>
                <th class="text-center">Site</th>
                <th class="text-center">SEU</th>
                <th class="text-center">Event Type</th>
                <th class="text-end">Cost({{getDashboardCurrencies()[dashboardCurrency].symbol}})
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="action in keywordFilteredList" :key="action.id" @click="goToActionView(action.id.toString())">
                <td class="text-center field-number">{{action.created_date}}</td>
                <td class="text-start field-description">{{replaceNewlines(action.comment) | truncatechars(120) }}</td>
                <td class="text-center field-number">{{action.status}}</td>
                <td class="text-center field-number">{{action.site_code__site_name}}</td>
                <td class="text-center field-number">{{action.seu__seu}}</td>
                <td class="text-center field-opportunity">{{action.event_type__event_type}}</td>
                <td class="text-end field-number">{{Math.round(action.cost * getDashboardCurrencies()[dashboardCurrency].multiplier).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { sortTableNew, sortOppTableCurrencyInt } from "@/common/tables.js";
import { truncatechars } from "@/common/general.js";
import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "ActionStandardSearch",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      active: "opportunities",
      siteList: null,
      searchQuery: null,
      site: "",
      seu: "",
      status: "open",
      dashboardCurrency: "EUR",
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getServiceSitesAndSeu", "isLoggedIn", "getLoader",
                    "getResultCount", "getActions", "getSiteNames", "getSiteCompanyNames", "getDashboardCurrencies" ]),
    ...mapActions([ "performSetFormError", "fetchAllActions", "fetchServiceSitesAndSeu", "performSetFormError", "fetchSiteNames"]),
    sortTableNew: sortTableNew,
    sortOppTableCurrencyInt: sortOppTableCurrencyInt,
    replaceNewlines(comment) {
        // Replace newline characters with " | "
        return comment.replace(/\n/g, ' | ');
    },
    getFullActionList() {
      this.fetchAllActions();
    },
    clearSeu() {
        if (this.site === "") {
          this.seu = ""
          this.status = ""
          this.search = ""
        } else {
          this.seu = ""
          this.status = ""
          this.search = ""
        }
    },
    clearSearch() {
       this.search = "";
    },
    goToActionView(id) {
      if (event.ctrlKey === true) {
//        let routeData = this.$router.resolve({ name: 'action-view', params: { id: id } });
//        window.open(routeData.href, '_blank');
        window.open(`/action-details/${id}`, '_blank');
      } else {
//        this.$router.push({ name: 'action-view', params: { id: id } });
//        location.href=(`/action-details/${id}`);
        window.open(`/action-details/${id}`, '_blank');
      }
    },
  },
  computed: {
    filteredList() {
        if (this.seu !== ""  && this.site !== "" && this.status !== "" ) {
            return this.getActions().filter(action => {
              return action.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      action.seu__seu.toLowerCase().includes(this.seu.toLowerCase()) &&
                      action.status.toLowerCase().includes(this.status.toLowerCase())
            })
        } else if (this.site !== ""  && this.seu === "" && this.status === "") {
            return this.getActions().filter(action => {
              return action.site_code__site_code.toLowerCase() === this.site.toLowerCase()
            })
        } else if (this.site !== ""  && this.seu !== "" && this.status === "") {
            return this.getActions().filter(action => {
              return action.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                     action.seu__seu.toLowerCase().includes(this.seu.toLowerCase())
            })
        } else if (this.site !== "" && this.seu === "" && this.status !== "") {
            return this.getActions().filter(action => {
              return action.site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                     action.status.toLowerCase().includes(this.status.toLowerCase())
            })
        } else if (this.site === "" && this.seu === "" && this.status !== "") {
            return this.getActions().filter(action => {
              return action.status.toLowerCase().includes(this.status.toLowerCase())
            })
        } else {
          return this.getActions()
        }
    },
    keywordFilteredList() {
      return this.filteredList.filter(action => {
        return action.action_number.toLowerCase().includes(this.search.toLowerCase()) ||
               action.site_code__site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               action.site_code__site_name.toLowerCase().includes(this.search.toLowerCase()) ||
               action.status.toLowerCase().includes(this.search.toLowerCase()) ||
               action.seu__seu.toLowerCase().includes(this.search.toLowerCase()) ||
               action.event_type__event_type.toLowerCase().includes(this.search.toLowerCase()) ||
               action.comment.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  filters: {
    truncatechars: truncatechars
  },
  created() {
    this.fetchSiteNames();
    this.fetchServiceSitesAndSeu();
    this.performSetFormError(null);
    this.getFullActionList();
    document.title = "Action Search";
    window.scrollTo(0,0);
  },

}
</script>


<template>
       <div class="row plain-element">

        <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 plain-element text-end"></div>
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-2 plain-element text-end">
            <div class="row plain-element text-start">
                <img alt="img" src="https://saperapublicstorage.blob.core.windows.net/media/visio.png"
                       class="img responsive">
            </div>
          </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-5 plain-element text-end"></div>

      </div>
</template>

<script>



export default {
  name: "DatabasesSqlDiagramComponent",
  props: {

  },
  components: {

  },
  data() {
    return {

    }
  },
  methods: {

  },

  computed: {


  },
  created() {


  },
}
</script>

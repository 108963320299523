import { Doughnut, mixins } from 'vue-chartjs';
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin

// Register required components for the doughnut chart
ChartJS.register(Tooltip, Legend, ArcElement, ChartDataLabels);

const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        plugins: {
          legend: {
            position: 'top',
            padding: 25,
            labels: {
              font: {}
            }
          },
          datalabels: {
            color: '#fff',
            anchor: 'end',
            align: 'start',
            formatter: (value) => `${value}%`, // Format labels as percentages
            font: {
              weight: 'bold',
              size: 20
            },
            display: (context) => {
              // Only display the label if the value is greater than 1%
              const value = context.dataset.data[context.dataIndex];
              return value > 1; // Show label only if the value is greater than 1%
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
}

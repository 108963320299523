<template>
    <div class="row plain-element">
        <div class="modal fade" id="enpiModal" tabindex="-1" aria-labelledby="enpiModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 50%;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="enpiModalLabel">{{ modalMeterName }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="white-space: pre-wrap; text-align: left;">
                        <span v-html="highlightOperators(modalMeterCalculation)"></span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="full_dataset_dict.length > 0" class="row plain-element">
            <div class="row plain-element">
                <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-start">
                    <button class="btn btn-report margin-left-0 margin-bottom-10" @click="toggleRow">
                        <span v-if="!isRowVisible">Explain it to me</span>
                        <span v-else>Hide Information</span>
                    </button>
                </div>
            </div>
            <p class="w-100"></p>
            <div v-if="computedIsRowVisible" class="row plain-element" id="explainTable">
                <div class=" col col-12 col-sm-12 col-md-6 col-lg-4 plain-element text-end">
                    <div class="text-start">
                        <span><b>Tag Name:</b>  SQL Name. Use this name when requesting a change via ticket.</span>
                    </div>
                    <div class="text-start margin-top-5">
                        <span><b>Description:</b>  Meter Maps description.</span>
                    </div>
                    <div class="text-start margin-top-5">
                        <span><b>Units:</b>  Meter Maps units.</span>
                    </div>
                    <div class="text-start margin-top-5">
                        <span><b>SEU:</b>  The SEU corresponding to the tag. If your site has no SEU columns, you can fill in the SEUs for the tags in Excel and<a href="https://em3-support.atlassian.net/servicedesk/customer/portal/1" target="_blank"> submit a ticket</a> with it.</span>
                    </div>
                    <div class="text-start margin-top-5">
                        <span><b>View:</b>  Tag location in SQL.</span>
                    </div>
                </div>
                <div class=" col col-12 col-sm-12 col-md-1 col-lg-1 plain-element text-end"></div>
                <div class=" col col-12 col-sm-12 col-md-7 col-lg-7 plain-element text-end">
                    <div class="text-start">
                        <span><b class="font-dark-grey">SQL-only:</b>  These meters exist in our database but were not added to the SAPERA View which is what Energy Eye and SEU Dashboards use. You can still analyse these meters with the Meter Health Check dashboard or submit a ticket if you need them to be added to an SEU Dashboard.</span>
                    </div>
                    <div class="text-start margin-top-8">
                        <span><b class="font-dark-moderate-lime-green">Fixed:</b> This is where we put meters that require to be modified before they're put in the SAPERA View to be used in calculations. Common examples would be to set a problematic meter's negative values to 0, detotalise a meter or apply a TimeStamp condition to modify the meter for a set period.</span>
                    </div>
                    <div class="text-start margin-top-8">
                        <span><b class="font-em3-blue">SAPERA View:</b>  This contains all meters and EnPIs that can be viewed in Power BI.</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="w-100"></p>
        <div v-if="full_dataset_dict.length === 0" class="row plain-element text-start">
            <span>No Meter Tags table found for this site.</span>
        </div>
        <div v-else class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-4 col-lg-2 plain-element text-end">
                <div class="input-field  col col-12 col-sm-12 col-form">
                    <div class="row searchbox-wrapper">
                        <div class="row plain-element text-start">
                            <label class="label-search">Table Filter</label>
                        </div>
                        <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                               aria-label="e.g. Steam" v-model="search">
                    </div>
                </div>
            </div>
            <div class=" col col-12 col-sm-12 col-md-4 col-lg-1 plain-element text-end"></div>
            <div class="col col-12 col-sm-12 col-md-4 col-lg-6 plain-element text-start">
                <div class="row plain-element">
                    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 plain-element d-flex align-items-center">
                        <div class="form-check me-5 form-tick-box">
                            <input class="form-check-input" type="checkbox" id="meters" v-model="meters">
                            <label class="form-check-label" for="meters">Meters</label>
                        </div>
                        <div class="form-check form-tick-box">
                            <input class="form-check-input" type="checkbox" id="enpi" v-model="enpi">
                            <label class="form-check-label" for="enpi">EnPIs</label>
                        </div>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-8 col-lg-1 plain-element"></div>
                    <div class="col col-12 col-sm-12 col-md-8 col-lg-6 plain-element text-start d-flex align-items-center">
                        <div class="form-check me-5 form-tick-box">
                            <input class="form-check-input" type="checkbox" id="sqlOnly" v-model="sqlOnly">
                            <label class="form-check-label" for="sqlOnly">SQL-only</label>
                        </div>
                        <div class="form-check me-5 form-tick-box">
                            <input class="form-check-input" type="checkbox" id="fixed" v-model="fixed">
                            <label class="form-check-label" for="fixed">Fixed</label>
                        </div>
                        <div class="form-check form-tick-box">
                            <input class="form-check-input" type="checkbox" id="sapera" v-model="sapera">
                            <label class="form-check-label" for="sapera">SAPERA</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="full_dataset_dict && getLoader() === false" class="row plain-element">
            <div v-if="full_dataset_dict.length > 0" class=" col col-12 col-sm-12 col-md-12 col-lg-10 text-start">
                <div id="parent">
                    <div id="child">
                        <table id="searchTable">
                            <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                            <tr>
                                <th @click="sortTableNew(0)" class="text-start">Tag Name
                                    <span class="fa fa-stack">
                                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                                    </span>
                                </th>
                                <th @click="sortTableNew(1)" class="text-start">Description
                                    <span class="fa fa-stack">
                                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                                    </span>
                                </th>
                                <th @click="sortTableNew(2)" class="text-start">Unit
                                    <span class="fa fa-stack">
                                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                                    </span>
                                </th>
                                <th @click="sortTableNew(3)" class="text-start">SEU
                                    <span class="fa fa-stack">
                                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                                    </span>
                                </th>
                                <th @click="sortTableNew(4)" class="text-start">View
                                    <span class="fa fa-stack">
                                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                                    </span>
                                </th>
                                <th @click="sortTableNew(5)" class="text-start">Formula <span style="color: #0b5cfc">(CLICK ROW TO ENLARGE)</span>
                                    <span class="fa fa-stack">
                                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <thead v-else>
                                <tr>
                                    <th class="text-start">Tag Name</th>
                                    <th class="text-start">Description</th>
                                    <th class="text-start">Unit</th>
                                    <th class="text-start">SEU</th>
                                    <th class="text-start">View</th>
                                    <th class="text-center">Formula(Click to Enlarge)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(meter, index) in keywordFilteredList" :key="index" @click="showModal(meter)" data-bs-toggle="modal" data-bs-target="#enpiModal">
                                    <td class="text-start field-text-15">{{ meter.name }}</td>
                                    <td class="text-start field-text-15">{{ meter.description }}</td>
                                    <td class="text-start field-number">{{ meter.unit }}</td>
                                    <td class="text-start field-number">{{ meter.seu }}</td>
                                    <td class="text-start field-text">
                                        <span v-if="meter.table_view === 'Fixed'" class="font-dark-moderate-lime-green">{{ meter.table_view }}</span>
                                        <span v-else-if="meter.table_view === 'SAPERA'" class="font-em3-blue">{{ meter.table_view }}</span>
                                        <span v-else>{{ meter.table_view }}</span>
                                    </td>
                                    <td class="text-start field-description">
                                        <span v-if="meter.table_view === 'Not in SQL'" class="grey">{{ meter.calculation | truncatechars(200) }}</span>
                                        <span v-else>{{ meter.calculation | truncatechars(200) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else class=" col col-12 col-sm-12 col-md-12 col-lg-6 text-start">

                <div id="parent">
                    <div id="child">
                        <table id="searchTable">
                            <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                            <tr>
                                <th @click="sortTableNew(0)" class="text-start">Tag Name
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>

                                </th>
                                <th @click="sortTableNew(1)" class="text-start">Formula <span style="color: #0b5cfc">(CLICK ROW TO ENLARGE)</span>
                                    <span class="fa fa-stack">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                              <i class="fa fa-caret-up" aria-hidden="true"></i>
                          </span>
                                </th>

                            </tr>
                            </thead>
                            <thead v-else-if="keywordFilteredList.length > 10000">
                            <tr>
                                <th class="text-start">Tag Name
                                </th>
                                <th class="text-center">Formula(Click to Enlarge)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(meter, index) in keywordFilteredList" :key="index" @click="showModal(meter)" data-bs-toggle="modal" data-bs-target="#enpiModal">
                                <td class="text-start field-opportunity">{{ meter.name }}</td>
                                <td class="text-start field-description">{{ meter.calculation | truncatechars(200) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { sortTableNew, sortTableCurrencyInt } from "@/common/tables.js";
import { truncatechars } from "@/common/general.js";

export default {
  name: "DatabasesMeterTableComponent",
  props: {
    site_code: {
      type: String,
      required: true,
    },
    full_dataset_dict: {
      type: Array,
      required: true,
    },
  },
  components: {

  },
  data() {
    return {
      power_bi: "All",
      search: "",
      site: "",
      modalMeterName: "",
      modalMeterCalculation: "",
      enpiChecked: false,
      isRowVisible: false,
      sqlOnly: true,
      fixed: true,
      sapera: true,
      meters: true,
      enpi: true
    }
  },
  methods: {
    ...mapGetters(["getLoader"]),
    sortTableNew: sortTableNew,
    sortTableCurrencyInt: sortTableCurrencyInt,
    clearSearch() {
       this.search = "";
    },
    replaceNewlines(text) {
      // Replace \r and \n with their escape sequences
      return text.replace('BETWEEN', '\n');
    },
    showModal(meter) {
      this.modalMeterName = meter.name;
      this.modalMeterCalculation = meter.calculation;
    },
    highlightOperators(text) {
        // Define regular expressions for SQL functions, strings, and comments
        const functionRegex = /\b(IIF|NULLIF|ISNULL|SUM|AVG|LAG|LEAD|COALESCE|CONCAT|FORMAT|COUNT|MAX|MIN|POWER|SQRT|DATEADD|DATEDIFF|DATEFROMPARTS|DAY|GETDATE|MONTH|YEAR)\b/gi;
        const stringRegex = /'[^']*'|"[^"]*"|\[[^\]]*\]/g;
        const commentRegex = /--.*$|\/\*[\s\S]*?\*\//gm;

        // Color code SQL functions
        text = text.replace(functionRegex, '<span class="sqlFunction">$&</span>');

        // Color code strings
        text = text.replace(stringRegex, (match) => {
            // If the match starts and ends with square brackets, color it light grey
            if (match.startsWith('[') && match.endsWith(']')) {
                return `<span class="lightGrey">${match}</span>`;
            } else {
                // Otherwise, leave the match unchanged
                return match;
            }
    });

    // Color code comments
    text = text.replace(commentRegex, (match) => {
        // Check if the comment contains any SQL function names
        if (functionRegex.test(match)) {
            // If so, color code both the comment and the SQL function names
            return match.replace(functionRegex, '<span class="comment">$&</span>');
        } else {
            // Otherwise, color code only the comment
            return `<span class="comment">${match}</span>`;
        }
    });

    return text;
},
    toggleRow() {
      this.isRowVisible = !this.isRowVisible;
    },

  },
  computed: {
    computedIsRowVisible() {
      return this.full_dataset_dict.length > 0 && this.isRowVisible;
    },
    filteredList() {
      return this.full_dataset_dict.filter(meter => {
        return (this.enpi && meter.name.includes(this.site_code)) ||
               (this.meters && !meter.name.includes(this.site_code));
      });
    },
    categoryFilteredList() {
      return this.filteredList.filter(meter => {
        return (this.sqlOnly && meter.table_view.toLowerCase().includes('sql-only')) ||
               (this.fixed && meter.table_view.toLowerCase().includes('fixed')) ||
               (this.sapera && meter.table_view.toLowerCase().includes('sapera'));
      });
    },
    keywordFilteredList() {
      return this.categoryFilteredList
        .filter(meter =>
          meter.name.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.description.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.calculation.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.seu.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.unit.toLowerCase().includes(this.search.toLowerCase()) ||
          meter.table_view.toLowerCase().includes(this.search.toLowerCase())
        );
    },

  },
  filters: {
    truncatechars: truncatechars
  },
  created() {


  },
}
</script>